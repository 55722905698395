import "./CardForHowWeWork.css";
import React, { useEffect, useRef, useState } from "react";
import chat from "./icons/chat.png";
import plan from "./icons/checklist.png";
import idea from "./icons/idea.png";
import design from "./icons/tools.png";
import launch from "./icons/new-product.png";
import growth from "./icons/economic.png";
import Model from "./Model";
import { useInView } from "framer-motion";

const CardForHowWeWork = () => {
  const data = [
    {
      logo: chat,
      name: "Communication",
      class: "blue",
      templateContent:
        "Crafting a shared vision to unify efforts, foster collaboration, and establish purpose that guides and inspires mutual success through aligned actions.",
      actualContent:
        "Our various teams, including development, design, and marketing-sales, are dedicated to addressing specific objectives and requirements. We thoroughly evaluate existing ideas, strategies, and details, engaging in challenges and offering our insights along the way. Our aim is to assist you in fully articulating your needs. Additionally, we will introduce ourselves, providing comprehensive information about our services, enabling you to gain a thorough understanding of what we offer.",
    },
    {
      logo: plan,
      class: "violet-milta",
      name: "Plan",
      templateContent:
        "Crafting a strategic roadmap for consistent optimal outcomes through a systematic approach integrating innovation and efficiency.",
      actualContent:
        "To kickstart our planning process, we begin by gathering requirements to construct a detailed project scope statement. The work breakdown structure plays a crucial role in project management, facilitating both planning and coordination. We strategically allocate resources and tasks to our team members based on their skills and interests. This ensures efficient project execution and aligns with our commitment to effective project management practices.",
    },
    {
      logo: idea,
      name: "Idea",
      class: "orange",
      templateContent:
        "Embarking on a journey of exploration, unlocking possibilities through curiosity, and nurturing a mindset for innovation and visionary pursuits.",
      actualContent:
        "Our approach centers around delving into your business objectives, vision, and expectations to shape and refine ideas. We firmly believe that every small concept contributes to achieving significant goals for a company or project. In a collaborative effort, we work closely with you to pinpoint the crucial details that define features, functions, content categories, technical specifications, and time complexity necessary for successful implementation.",
    },
    {
      logo: design,
      name: "Design",
      class: "cream-green",
      templateContent:
        "Embarking on a breathtaking voyage of genuine exploration and vivid dreaming, aspiring to weave a unique tapestry of real experiences.",
      actualContent:
        "At this juncture, we transition your business and projects into a visual reality. Given our diverse expertise across various domains, we specialize in crafting customized systems tailored to meet specific requirements. Our development team excels in constructing a visually distinctive brand identity and prioritizes a user-friendly experience. Drawing insights from team suggestions, we progress to the final stages of application development, incorporating your data and preparing for the official launch.",
    },
    {
      logo: launch,
      name: "Launch",
      class: "purple",
      templateContent:
        "Crafting vibrant realities from ideas with meticulous creativity, precision, and a harmonious blend of innovation and passion, manifesting the extraordinary.",
      actualContent:
        "Upon completing all major processes, we conduct a thorough Quality Control Check to ensure that the product aligns with the original specifications. Once the quality check is successfully completed, we deploy the system to the production environment, allowing users to commence using the product. This signifies the culmination of our development process. We extend free technical support for a specified period and remain readily available for any necessary additions or improvements, demonstrating our commitment to ongoing assistance and enhancement.",
    },
    {
      logo: growth,
      name: "Growth",
      class: "Lime",
      templateContent:
        "Transforming brands with innovative, ROI-driven marketing that fosters authentic connections through compelling storytelling and data-driven creativity.",
      actualContent:
        "In this era dominated by advancing technology, maintaining visibility and generating revenue within the digital landscape is crucial for business success. We are steadfast in our commitment to ensuring your business not only stays relevant but also thrives in the evolving digital landscape. Our comprehensive range of online marketing services is designed to simplify your business processes and foster growth. Our aim is to help you achieve maximum Return on Investment (ROI) and establish your business as a reputable brand in the digital realm.",
    },
  ];

  const [showModal, setShowModal] = useState(null);
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {}, [isInView]);
  return (
    <>
      <div
        ref={ref}
        style={{
          transform: isInView ? "none" : "translateX(-100px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.61, 1, 0.88, 1) 0.5s",
        }}
        className="CardForHowWeWork-main"
      >
        {data.map((item, index) => (
          <div key={index} className="CardForHowWeWork-main-cardDiv">
            <div className={`main-cardDiv-img ${item.class}`}>
              {/* <div className=""> */}
              <img src={item.logo} alt="img" />
              {/* </div> */}
            </div>
            <div className="main-cardDiv-content">
              <h2 className="main-cardDiv-h1">{item.name}</h2>
              <p className="main-cardDiv-para">{item.templateContent}</p>
            </div>
            <div className="main-cardDiv-button">
              <button
                onClick={() => {
                  setShowModal(index);
                }}
              >
                View More
              </button>
            </div>
            {showModal === index && (
              <div className="modal-opening-card">
                <Model
                  onClose={() => {
                    setShowModal(null);
                  }}
                  name={item.name}
                  logo={item.logo}
                  actualContent={item.actualContent}
                  classes={item.class}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default CardForHowWeWork;
