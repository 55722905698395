// export default Clients;
import React from "react";
import "./Clients.css";
import img1 from "./images/img1.png";
import img2 from "./images/img2.png";
import img3 from "./images/img3.png";
import img4 from "./images/img4.png";
import img5 from "./images/img5.png";
import img6 from "./images/img6.png";
import img7 from "./images/img7.png";
import img8 from "./images/img8.png";
import img9 from "./images/img9.png";
import img10 from "./images/img10.png";
import Marquee from "react-fast-marquee"

const Clients = () => {
  const row2 = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];

  return (
    <>
      <div id="second" className="client-section">
        <div className="wrapper">
          <p className="Text">Our Esteemed Clients</p>
          <p className="sub-heading">
            Empowering Your Vision, Enriching Our Journey Together
          </p>
          
          <Marquee 
          speed={100}
          className="marquee-container"
          >
            {row2.map((ele, index)=>(
              <div key={index} className="Image-Group">
                <img src={ele} alt="" className="images" />
              </div>
            ))}
          </Marquee>

        </div>
      </div>
    </>
  );
};

export default Clients;
