import "./Vision.css";
import mission from "./Images/mission.png";
import vission from "./Images/vision.png";
import { useEffect } from "react";
import Aos from "aos";
import 'aos/dist/aos.css';
const Vision = () => {
 useEffect(()=>{
  Aos.init({
    duration: 1000 ,
    offset: 300,
  })
 },[])
  return (
    <>
      <div className="vision-mission-home"> 

        <div className="main-vision" data-aos="fade-left">
          <div className="vision-home">
            <div className="vision-home-imgdiv">
              <img src={vission} alt="vision" />
            </div>

            <div className="content">
              <h2 className="heading-vision-home">Vision</h2>
              <p className="para-vision-home">
                Our visionary goal is to fortify the Ministry of Micro, Small &
                Medium Enterprises (MSME) in India through the strategic
                integration of emerging technologies, fostering a dynamic
                environment that equips them with advanced tools and
                capabilities, ultimately ensuring their empowerment and
                sustained growth.
              </p>
            </div>
          </div>
          <div className="vision-line"></div>
        </div>

        <div className="main-mission" data-aos="fade-right">
          <div className="mission-line"></div>
          <div className="mission-home">
            <div className="content">
              <h2 className="heading-mission-home">Mission</h2>
              <p className="para-mission-home">
                Our mission is dedicated to creating scalable and sustainable
                business solutions uniquely crafted for small and medium-sized
                enterprises in India, enabling them to achieve accelerated
                growth and long-term success through our strategic support.
              </p>
            </div>
            <div className="mission-home-imgdiv">
              <img src={mission} alt="vision" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vision;
