import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import WeighbridgeInfo from "./WeighBridgeInfo/WeighbridgeInfo";
import Weightimg from "../../assets/weighbridgeimage.png";
import Aos from "aos";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import "./Products.css";

function Weight() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 300,
    });
  }, []);

  return (
    <div className="container my-5 py-5 z-depth-1">
      <section className="px-md-5 mx-md-5 dark-grey-text text-center text-lg-left">
        <div
          className="row flex-column-reverse flex-lg-row"
          data-aos="fade-right"
        >
          <div className="col-lg-6 mb-4 mb-lg-0 ">
            <h3 className="font-weight-bold">
              Transform Your Weighbridge Operations
            </h3>

            <p className="font-weight-bold">
              {" "}
              Efficiency, Automation, and Reliability Redefined{" "}
            </p>

            <p className="text-muted Text-in-Trusted">
              Experience a seamless transition into the future of weighbridge
              operations with the Growbean Unmanned RFID Weighbridge System. Our
              innovative solution empowers drivers to handle input,
              identification, and data with ease, transforming your weighbridge
              into a 24/7 operation without the need for dedicated personnel.
              Customize your system with RFID integration, vehicle positioning,
              surveillance options, and seamless integration with existing
              software. Join the revolution and streamline your industrial
              processes today.
            </p>

            <Link className="font-weight-bold" to="/weighbridgeinfo">
              Learn more{" "}
              <FontAwesomeIcon className="arrow-icon" icon={faAnglesRight} />{" "}
              <i className="fas fa-angle-right ml-2"></i>
            </Link>
          </div>

          <div className="col-lg-6 mb-4 mb-lg-0 d-flex align-items-center justify-content-center  ">
            <img
              src={Weightimg}
              className="img-fluid  shadow   bg-body "
              alt=""
              data-aos="fade-left"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Weight;
