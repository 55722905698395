import React from "react";
import { motion } from "framer-motion";
import "./MoreServices.css";
import ecommBanner from './images/ecommBanner2.jpeg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faPhoneVolume,
  faPhone,
  faEnvelope,
  faBackward,
  faForward,
} from "@fortawesome/free-solid-svg-icons";

function Ecommers() {
  const leftVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  // const leftVariants = {
  //   hidden: { opacity: 0, x: 100 },
  //   visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  // };

  return (
    <>
      <h2 className="ecommerce-heading">E-Commerce Service</h2>
      <div className="service-container" id="top">
        <div className="service-data-container">
          <div className="image-container">
            <p className="hover-text">The scope of e-commerce services is vast and continuously evolving with advancements in technology and changing consumer preferences. By leveraging e-commerce solutions, businesses can unlock new opportunities for growth, expand their customer base, and stay competitive in today's digital marketplace.</p>

            <img
              className="img-moredetails"
              src={ecommBanner}
            />
          </div>

          <div className="content-section">
            <motion.h1
              className="fw-bold mt-5"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              Growbean: E-commerce Solutions
            </motion.h1>
            {/* <motion.h5
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              Comprehensive Online Retailing Services
            </motion.h5> */}
            <motion.p
              className="mt-3 text-body-secondary"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              Growbean: E-commerce Solutions: Our comprehensive e-commerce solutions empower businesses to establish and expand their online presence, offering a seamless shopping experience for customers worldwide. Through user-friendly interfaces and robust features, we provide a platform for businesses to showcase their products or services, manage orders efficiently, and facilitate secure payment processing. With a focus on scalability and flexibility, our e-commerce solutions cater to businesses of all sizes, from startups to enterprise-level corporations. Growbean E-commerce services encompass a wide range of businesses that support the various aspects of online buying and selling.
            </motion.p>

            <motion.h2
              className="fw-bold"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              Ecommerce services provide by our company
            </motion.h2>
            <motion.p
              className="text-body-secondary"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              E-commerce, or electronic commerce, has revolutionized the way
              businesses operate and consumers shop. The scope of e-commerce
              services encompasses a wide range of activities and
              functionalities aimed at facilitating online transactions and
              enabling businesses to reach their target audience effectively.
              Below are the key aspects of the scope of e-commerce services:
              <br /> Our services include: <br />
              <br />
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">1.Ecommerce website development:</span>{" "}
                Customized to reflect your brand identity.
                Designed to cater to your target audience.
                Aligned with your specific business goals.
                Intuitive navigation for easy product discovery.
                Responsive layouts for optimal viewing on any device.
                Engaging visuals to enhance user experience.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">2.  Shopping cart software :</span>{" "}
                Easily integrates with your existing website and e-commerce platform.
                Compatible with various payment gateways and third-party applications.
                Intuitive design for easy navigation and use.
                Simple and quick checkout process to enhance customer experience.
                Fully customizable to match your brand’s look and feel.
                Customizable product pages, cart layouts, and checkout forms.
                Flexible discount and promotion options to attract and retain customers.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">3. Payment Processing:</span>{" "}
                Supports a wide range of payment methods, including credit/debit cards, digital wallets, and online banking.
                Enables customers to choose their preferred payment option for convenience and flexibility.
                Advanced security protocols to protect sensitive customer data.
                PCI DSS compliance ensures secure and encrypted transactions.
                Fraud detection and prevention measures to safeguard against unauthorized activities.Instant payment authorization and processing to speed up transactions.
                Real-time transaction monitoring and reporting for accurate financial management.
                Immediate updates on payment status to keep both you and your customers informed.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">4. Security solutions:</span>{" "}
                Advanced security measures to protect your e-commerce platform from cyber threats.
                Multi-layered defense strategies including firewalls, anti-virus, and anti-malware systems.
                Regular security audits and vulnerability assessments to identify and mitigate risks.
                Implementation of strong encryption protocols to safeguard sensitive customer information.
                Ensures secure data transmission between your site and customers.
                Protects against data breaches and unauthorized access.
              </motion.p>
              
            </motion.p>
          </div>

        </div>
      </div>
      <div className="d-flex justify-content-around m-5">
        <Link to="/softwaredevelopment">
          <button class="bn53">
            <FontAwesomeIcon icon={faBackward} /> Prev
          </button>
        </Link>
        <Link to="/systemintegrator">
          <button class="bn53">
            Next <FontAwesomeIcon icon={faForward} />
          </button>
        </Link>
      </div>
    </>
  );
}

export default Ecommers;
