import "./Career.css";
import team from "./team2.jpeg";
// import JobCard from "../../component/JobCard/JobCard";
import JobOpening from "../../component/JobOpening/JobOpening";

const Career = () => {
  return (
    <>
      <div className="image-main2-div-career dark-grey-text">
        <div className="poster-div-career ">
          <h5 className="text-heading fw-lighter">Join Our Growbean Family </h5>
          <h2 className="m--10 fw-bold heading-d-career">
            At Growbean Solutions Empowering amazing indidual to thrive join us
            for career growth, a vibrant community and unleash your brilliance.
          </h2>
          <a href="#jobOpenings" class="btn41-43 btn-41">
            Job Openings
          </a>
        </div>
        <div className="image-career-div">
          <img src={team} className="img-fundimg" alt="img" />
        </div>
      </div>
      <div id="jobOpenings">
        <JobOpening />
      </div>
    </>
  );
};

export default Career;
