import React from "react";
import "./Hero.css";
import Carousel from "react-bootstrap/Carousel";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import video1 from "./video1.mp4";

const Hero = () => {
  useEffect(() => {
    Aos.init({
      duration: 6000,
      offset: 300,
    });
  }, []);
  return (
    <Carousel>
      <Carousel.Item interval={6000}>
        <div className="hero-container">
          <video muted autoPlay loop className="hero-image">
            <source src={video1} type="video/mp4" />
          </video>

          <div className="hero-content">
            <h1>Empower Your Business With Innovative Solutions</h1>
            <p>
              "Efficiency unleashed, innovation realized. Automation excellence
              for your business success.
            </p>
            <a href="#second">
              <button>Let's Start</button>
            </a>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={6000}>
        <div className="hero-container">
          <video muted autoPlay loop className="hero-image">
            <source src={video1} type="video/mp4" />
          </video>

          <div className="hero-content" data-aos="fade-down">
            <h1>Streamline Your Success With Seamless Automation</h1>
            <p>
              Transforming Indian SMEs with User-friendly Software and Strategic
              Support
            </p>
            <a href="#second">
              <button>Let's Start</button>
            </a>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="hero-container" data-aos="fade-down">
          <video muted autoPlay loop className="hero-image">
            <source src={video1} type="video/mp4" />
          </video>

          <div className="hero-content">
            <h1>Automate To Innovate, Elevate, And Carouselaptivate</h1>
            <p>
              Transforming operations, empowering efficiency, revolutionizing
              the future of automation
            </p>
            <a href="#second">
              <button>Let's Start</button>
            </a>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default Hero;
