import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "./Testimonial.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import required modules
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import SwiperCore from "swiper/core";
// import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay]);
SwiperCore.use([Autoplay, Pagination]);

export default function Testimonial() {
  return (
    <>
      <div className="section section-testimonial">
        <h2 className="text-center d-flex justify-content-center align-items-center mb-5">
          <div className="bg-success span-line"></div>
          <div className="m-2 he-1"> Happy client Works</div>
          <div className="bg-success span-line"></div>
        </h2>

        <Swiper
          slidesPerView={2}
          spaceBetween={30}
          autoplay={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          <div className="swiper myswiper container ">
            <div className="swiper-wrapper">
              {/* start */}
              <SwiperSlide className="swiper-slide">
                <div className="swiper-client-msg">
                  <p>
                    I highly recommend Growbean for their exceptional services,
                    which are characterized by efficiency, creativity, and
                    reliability.
                  </p>
                </div>
                <div className="swiper-client-data grid grid-two-column">
                  <div className="client-data-details">
                    <p className="client-name">Western Coalfield Limited</p>
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="swiper-client-msg">
                  <p>
                    Growbean stands out for its exemplary services, providing
                    innovative solutions and outstanding support to clients.
                  </p>
                </div>
                <div className="swiper-client-data grid grid-two-column">
                  <div className="client-data-details">
                    <p className="client-name">Yantra India Limited</p>
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="swiper-client-msg">
                  <p>
                    I've had a fantastic experience with Growbean, whose
                    services are marked by excellence, reliability, and a focus
                    on client satisfaction
                  </p>
                </div>
                <div className="swiper-client-data grid grid-two-column">
                  <div className="client-data-details">
                    <p className="client-name">Sterlite Technologies Limited</p>
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="swiper-client-msg">
                  <p>
                    "Growbean provides excellent service, delivering quality
                    solutions promptly. Their team is professional, responsive,
                    and dedicated to client satisfaction.
                  </p>
                </div>
                <div className="swiper-client-data grid grid-two-column">
                  <div className="client-data-details">
                    <p className="client-name">Adani Enterprises Ltd</p>
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="swiper-client-msg">
                  <p>
                    GrowBean made managing my business finances a breeze with
                    its user-friendly interface and responsive support team.
                  </p>
                </div>
                <div className="swiper-client-data grid grid-two-column">
                  <div className="client-data-details">
                    <p className="client-name">Saint Gobain</p>
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="swiper-client-msg">
                  <p>
                    I highly recommend GrowBean for its intuitive design, robust
                    features, and commitment to user success.
                  </p>
                </div>
                <div className="swiper-client-data grid grid-two-column">
                  <div className="client-data-details">
                    <p className="client-name">Sterlite Technologies Limited</p>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </div>
        </Swiper>
      </div>
    </>
  );
}
