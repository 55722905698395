import React from "react";
import "./MoreServices.css";
import { motion } from "framer-motion";
import ERPBanner from './images/ERPBanner.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneVolume,
  faPhone,
  faEnvelope,
  faBackward,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
function ERP() {
  const leftVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  // const leftVariants = {
  //   hidden: { opacity: 0, x: 100 },
  //   visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  // };

  return (
    <>
      <h2 className="ecommerce-heading">Enterprise Resource Planning</h2>
      <div className="service-container" id="top">
        <div className="service-data-container">
          <div className="image-container">
            <p className="hover-text">Growbean offers a comprehensive Enterprise Resource Planning (ERP) solution designed to streamline and optimize business operations across various departments and functions. Our ERP service integrates seamlessly with your existing systems, providing a unified platform for managing resources, processes, and data in real-time. .</p>

            <img
              className="img-moredetails"
              src={ERPBanner}
            />
          </div>

          <div className="content-section">
            <motion.h1
              className="fw-bold mt-5"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              Growbean: Enterprise Resource Planning Solutions
            </motion.h1>

            <motion.p
              className="mt-3 text-body-secondary"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              Growbean's ERP planning services are designed to streamline and optimize your business processes through effective Enterprise Resource Planning (ERP) solutions. We are committed to simplifying your workflow by seamlessly integrating with ERP systems, Mines & Minerals Management Systems, and other related units.
            </motion.p>

            <motion.h2
              className="fw-bold"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              Enterprise Resource Planning Services
            </motion.h2>
            <motion.p
              className="text-body-secondary"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >

              <br />
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">1. Finance:</span>{" "}
                Tracks financial data, automates accounting processes, and facilitates budgeting and forecasting.
                Human Resources (HR): Manages employee data, payroll, benefits administration, and recruitment.
                Supply Chain Management: Oversees inventory levels, procurement, logistics, and warehouse operations.
                Manufacturing: Optimizes production scheduling, material usage, and quality control.
                Sales & Marketing: Streamlines customer relationship management (CRM), sales order processing, and marketing campaigns
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">2. Improved Efficiency:</span>{" "}
                Streamlined workflows and information sharing lead to increased productivity and faster turnaround times.

              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">3. Enhanced Visibility::</span>{" "}
                Real-time data across departments provides a holistic view of the organization, enabling better decision-making.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">4.Reduced Costs:</span>{" "}
                Automation eliminates manual tasks, minimizes errors, and optimizes resource allocation, leading to cost savings.
                Improved Collaboration: A unified platform fosters better communication and collaboration between departments.
                Increased Customer Satisfaction: Faster order processing, improved inventory management, and better communication lead to happier customers.
              </motion.p>
            
              
            </motion.p>
          </div>

        </div>
      </div>
      <div className="d-flex justify-content-around m-5">
        <Link to="/securityserveillance">
          <button class="bn53">
            <FontAwesomeIcon icon={faBackward} /> Prev
          </button>
        </Link>
        <Link to="/itinfrastructure">
          <button class="bn53">
            Next <FontAwesomeIcon icon={faForward} />
          </button>
        </Link>
      </div>
    </>
  );
}

export default ERP;
