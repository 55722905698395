
const Products = () => {
    return (
        <>
           <div className="mar-30">
            <h2 className="text-center d-flex justify-content-center align-items-center ">
                <div className="bg-success span-line"></div>
                <div className="m-2 he-1">Our Products</div>
                <div className="bg-success span-line"></div>
            </h2>
            <p className="text-center">Explore our range of innovative products designed to drive your business forward</p>
            </div>
        </>
    )
}

export default Products