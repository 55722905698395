import React from 'react';
import './AboutPhilosophy.css'; // Import your CSS file for styling
import binoculars from './icons/binoculars.png'
import bullseye from './icons/target.png'
import users from './icons/group.png'

const AboutPhilosophy = () => {
  const data = [
    {
      logo: binoculars,
      name: "Our Vision",
       class:"pink",
      templateContent:
        "Our vision is to empower the ministry of micro, small & medium enterprises (MSME) India with emerging Technology, empowering MSMEs to thrive in the digital age and contribute to India's economic development.."
    },
    {
      logo: bullseye,
      name: "Our Mission",
       class:"purple",
      templateContent:
        "Our mission is to build scalable & sustainable business solutions for small & medium-sized enterprises in India and help them to grow quickly to contribute to technology and makes it valuable for the country."
    },
    {
      logo: users,
      name: "Our Culture",
      class:"blue",
      templateContent:
        "We are dedicatedly to working to tackle the customize needs of all-sized businesses in India. We develop an enterprise in the Indian Market with a group of people, great talent, expertise, and experience in the domain."

    },
  ];
  return (
    <div className="about-philosophy-container">
      <div className="about-philosophy">
        <div className="our-philosophy-container">
          <div className="our-philosophy-heading">Our Philosophy</div>
          <div className="philosophy-section">
          
            <p className='philosophypara'>
             We believe in diversity and different backgrounds, perspectives, and ideas, which makes us a stronger and more capable team. Each one of our workforce adds to our inclusive work culture that matters.
            </p>
          </div>
        </div>
      
      </div>
      <div className='CardForPhilosophy-main '>
        {data.map((item, index) => (
          <div key={index} className="CardForPhilosophy-main-cardDiv">
             <div className={`main-Philosophy-cardDiv-img ${item.class}`}>
            <div className="">
           <img src={item.logo} alt="img" />
            </div>
            </div>

            <div className="main-Philosophy-cardDiv-content">
              <h2 className="main-Philosophy-cardDiv-h1">{item.name}</h2>
              <p className="main-Philosophy-cardDiv-para">{item.templateContent}</p>
            </div>
          </div>
        ))}
        </div>
    </div>
    
  );
}

export default AboutPhilosophy;
