import "./Home.css";
import Vision from "../../component/Vision/Vision";
import ServiceCards from "../../component/ServiceCards/ServiceCards";
import Products from "../../component/Products/Products";
import Contact from "../../component/Contact/ContactOnHome";
import Bizpetro from "../../component/Products/Bizpetro";
import Weight from "../../component/Products/Weight";


import Testimonial from "../../component/Testimonials/Testimonial";

import Hero from "../../component/HeroHome/Hero";
import Clients from "../../component/Cleints/Clients";
import SocialLink from "../../component/SocialLink/SocialLink";

const Home = () => {
  return (
    <div className="home-body">
      <SocialLink />
      <Hero/>

     
      <Clients/>
      <Vision />
      <Products />
      <Bizpetro/>
      <Weight/>
      <ServiceCards />

      <Contact />
      <Testimonial/>
      
   </div>
  );
};

export default Home;