import "./Service.css"
import Customerimg from "./Customer.gif"
import Ecommerceimg from "./Ecommerce.gif"
import Communicationimg from "./communication.gif"
import Socialimg from "./Social.gif"
import Planningimg from "./Planning.gif"
// import Weighbridgeimg from "./Weighbridge.png"

const ServiceContaint = ({ title, description, ml }) => {
    return (
        <div className={`servic-contain ${ml} `}>
            <h2> {title} </h2>
            <div className="single-line"></div>
            <p className="service-description">
                {description}
            </p>
        </div>
    )
}

const ServiceImage = ({ srImg }) => {

    return (
        <div className="service-img">
            <img src={srImg} alt="service" />
        </div>
    )
}

const Service = () => {
    return (
        <>
            {/* <div className="poster-container">
            <div className="left-contain">
                <h2></h2>
                <p>
                
                </p>    
            </div>
            <div className="right-contain"> 
                <img src="" />
            </div>
        </div> */}

            <div className="container">
                <div className="row mobile-container section-hight">
                    <div className="col-lg-7 col-sm-12  px-sm-5">
                        <ServiceContaint title={"E-commerce "}
                            description={"E-commerce: Buy and sell goods/services online via the internet, offering flexibility in delivery. Convenience Shop from home for a wide range of products.Business Growth: Growbean Solutions specializes in B2B services, helping small businesses expand globally.Diverse Support: From electronics to groceries, jewelry to apparel, we enhance sales and productivity. Committed to quality and excellence, offering hassle-free online business setup.Local Empowerment: Assisting local vendors to step into e-commerce and grow their stores effectively."} />
                    </div>
                    <div className="col-lg-5 col-sm-12 ">
                        <ServiceImage srImg={Ecommerceimg} />
                    </div>
                </div>

                <div className="row section-hight">
                    <div className="col-lg-5 col-sm-6  ">
                        <ServiceImage srImg={Communicationimg} />
                    </div>
                    <div className="col-lg-7 col-sm-6  px-sm-5">
                        <ServiceContaint
                            title={"Communication"}
                            description={"Omni-channel Communication: Deliver video, voice, chat, text, and telephony experiences across apps, websites, and mobile platforms.Secure Cloud Infrastructure: Expand reach securely with a flexible cloud infrastructure.API-Enabled Engagement: Enhance applications with flexible SDKs and APIs for web, mobile (Android/iOS), and .NET platforms.Enterprise-Grade Solutions: Easily integrate reliable calling and messaging capabilities into your business with minimal code."} />
                    </div>
                </div>

                <div className="row mobile-container section-hight">
                    <div className="col-lg-7 col-sm-12  px-sm-5">
                        <ServiceContaint
                            title={"Customer Support"}
                            description={"Our business thrives on seamless integration of sales, marketing, and CRM operations within our advanced Customer Service Software. We prioritize proactive engagement, supported by comprehensive training and dynamic feedback mechanisms to interpret customer queries effectively. Real-time data accessibility and unified customer profiles enable personalized assistance, aided by advanced AI and NLP integration. Customer education initiatives enhance product understanding, fostering lasting relationships and ensuring success. Our integrated approach elevates the customer experience, solidifying our position as a customer-centric organization."} />
                    </div>
                    <div className="col-lg-5 col-sm-12 ">
                        <ServiceImage srImg={Customerimg} />
                    </div>
                </div>

                <div className="row  section-hight">
                    <div className="col-lg-5 col-sm-6  ">
                        <ServiceImage srImg={Socialimg} />
                    </div>
                    <div className="col-lg-7 col-sm-6  px-sm-5 ">
                        <ServiceContaint
                            ml={"ml-20"}
                            title={"Social Media"}
                            description={"Enhance your brand and foster customer connections through an elevated social media presence. In today's digital landscape, leveraging social platforms is essential for burgeoning businesses. Seamlessly connect with a global audience, garner invaluable insights, and amplify your brand's reach. Elevate your online visibility, engage with customers, and cultivate a thriving community around your brand. Let social media serve as your dynamic conduit for growth, influence, and sustained success."} />
                    </div>
                </div>

                <div className="row mobile-container section-hight">
                    <div className="col-lg-7 col-sm-12   px-sm-5">
                        <ServiceContaint
                            title={"Enterprise Resource Planning"}
                            description={"Discover the pinnacle of trusted, secure, and scalable Enterprise Resource Planning solutions in India. Seamlessly integrate financials, sales, CRM, inventory management, analytics, and reporting into one user-friendly platform. Revolutionize your business processes with ERP, the backbone of organizational efficiency. Streamline accounting, procurement, project management, and more across your enterprise. Elevate productivity and orchestrate seamless operations with cutting-edge ERP technology."} />
                    </div>
                    <div className="col-lg-5 col-sm-12 image-div">
                        <ServiceImage srImg={Planningimg} />
                    </div>
                </div>

                {/* <div className="row  section-hight">
                    <div className="col-lg-5 col-sm-6 Weighbridge-img ">
                        <ServiceImage srImg={Weighbridgeimg} />
                    </div>
                    <div className="col-lg-7 col-sm-6  px-sm-5 ">
                        <ServiceContaint
                            title={"Weighbridge Automation"}
                            description={"Revolutionize your business with Growbean Technologies' Weighbridge Automation tool, simplifying operations and boosting efficiency. Our user-friendly interface ensures seamless integration and increased productivity. By automating routine tasks, we streamline processes, making them more reliable and organized. Experience the confidence of precision and accuracy in every transaction, empowering your business to thrive. Embrace the future of technology-driven solutions with Growbean Technologies today."} />
                    </div>
                </div> */}
            </div>


        </>
    )
}

export default Service