import "./SocialLink.css"
import insta from "./instagram.png"
import facebook from "./facebook.png"
import tweet from "./twitter.png"
import yotube from "./youtube.png"
const SocialLink = ()=>{

    return(
        <>
        <div className="icons" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="500">
            <div className="icon-div">

                <img src={yotube} className="social-icon" />
                <img src={tweet} className="social-icon" />
                <img src={facebook} className="social-icon" />
                <img src={insta} className="social-icon" />
            </div>
        </div>
        </>
    )
}   
export default SocialLink ;