import "./About.css"

import AboutContent from "../../component/AboutComponents/AboutContent";
import AboutBackground from "../../component/AboutComponents/AboutBackground";
import AboutPhilosophy from "../../component/AboutComponents/AboutPhilosophy";
import Technologies from "../../component/AboutComponents/Technologies";

const About = () => {
    return (
        <>
        {/* <h1 className="heading"> About</h1> */}
          <AboutBackground/>
           <AboutContent/>
           <AboutPhilosophy/>
           <Technologies/>
        </>
    )
}

export default About