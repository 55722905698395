import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import Bizpetroimg from "./../../assets/bizpetro.avif";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Products.css";

function Bizpetro() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 300,
    });
  }, []);

  return (
    <div className="container my-5 py-5 z-depth-1">
      <section className="px-md-5 mx-md-5 dark-grey-text text-center text-lg-left">
        <div className="row">
          <div
            className="col-lg-6 mb-4 mb-lg-0 d-flex align-items-center justify-content-center  "
            data-aos="fade-right"
          >
            <img
              src={Bizpetroimg}
              className="img-fluid  shadow   bg-body "
              alt=""
            />
          </div>

          <div className="col-lg-6 mb-4 mb-lg-0" data-aos="fade-left">
            <h3 className="font-weight-bold">
              Bizpetro Your Trusted Energy Partner
            </h3>

            <p className="font-weight-bold">
              {" "}
              Empowering Businesses with Reliable Energy Solutions
            </p>

            <p className="text-muted Text-in-Trusted">
              At Bizpetro, we specialize in providing tailored energy solutions
              to businesses of all sizes. From optimizing energy consumption to
              ensuring uninterrupted supply, we are committed to supporting our
              clients in achieving their energy goals efficiently and
              sustainably. With our expertise and dedication, we aim to be your
              reliable partner in navigating the complex landscape of energy
              management, helping you streamline operations and drive growth.
            </p>

            <a
              className="font-weight-bold"
              href="https://bizpetro.com/"
              target="blank"
            >
              Learn more{" "}
              <FontAwesomeIcon className="arrow-icon" icon={faAnglesRight} />{" "}
              <i className="fas fa-angle-right ml-2"></i>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Bizpetro;
