import React from "react";
import "./MoreServices.css";
import { motion } from "framer-motion";
import systemintegBanner from './images/systemintegratorBanner.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneVolume,
  faPhone,
  faEnvelope,
  faBackward,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
function SystemIngegrator() {
  const leftVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  // const leftVariants = {
  //   hidden: { opacity: 0, x: 100 },
  //   visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  // };

  return (
    <>
      <h2 className="ecommerce-heading">System Integrator</h2>
      <div className="service-container" id="top">
        <div className="service-data-container">
          <div className="image-container">
            <p className="hover-text">In today's fast-paced business environment, accurate inventory control and efficient quoting are crucial for success. Integrating weighbridge data with your sales system can be a game-changer on both fronts..</p>

            <img
              className="img-moredetails"
              src={systemintegBanner}
            />
          </div>

          <div className="content-section">
            <motion.h1
              className="fw-bold mt-5"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              Growbean: System Integration Solutions
            </motion.h1>
            {/* <motion.h5
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
             System Integration Services
            </motion.h5> */}
            <motion.p
              className="mt-3 text-body-secondary"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
            Growbean: System Integrator: "At Growbean, we specialize in providing system integrated services that seamlessly connect all your IT components into a efficient framework. Our expert team ensures that your software, hardware, and network systems work together harmoniously, eliminating inefficiencies and reducing operational costs. Our System integrator services help businesses  connect and optimize various technology components to create a unified and functional system. By integrating these systems, we enhance your business productivity, streamline workflows, and enable better data management.
            </motion.p>

            <motion.h2
              className="fw-bold"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
             System Integration Services 
            </motion.h2>
            <motion.p
              className="text-body-secondary"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
             
              {/* <br /> Our services include: <br /> */}
              <br />
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">1. Real-Time Inventory Tracking:</span>{" "}
                1improve accuracy: Our team ensure to eliminate discrepancies between physical inventory and digital sales records. This reduces errors in order fulfillment and accounting, leading to a more streamlined workflow.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">2.Enhanced planning::</span>{" "}
                We have full proof plan to gain valuable insights into historical weighbridge data to forecast demand patterns. This data can be used for optimizing inventory management and purchasing strategies, ensuring you have the right amount of stock on hand at all times.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">3.Consultation:</span>{" "}
                System integrators assess the client's needs, existing systems, and goals to provide tailored solutions.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">4. Design:</span>{" "}
                We design a comprehensive system integration plan that outlines how different components will work together effectively.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">5. Implementation:</span>{" "}
                System integrators install, configure, and integrate hardware, software, and technologies to create a cohesive system.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">6. Testing (M-commerce):</span>{" "}
                They conduct thorough testing to ensure that all components are functioning correctly and the integrated system meets the client's requirements.
              </motion.p>
            </motion.p>
          </div>

        </div>
      </div>
      <div className="d-flex justify-content-around m-5">
        <Link to="/ecommerce">
          <button class="bn53">
            <FontAwesomeIcon icon={faBackward} /> Prev
          </button>
        </Link>
        <Link to="/securityserveillance">
          <button class="bn53">
            Next <FontAwesomeIcon icon={faForward} />
          </button>
        </Link>
      </div>
    </>
  );}

export default SystemIngegrator;
