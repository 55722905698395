import React from 'react'
import './weighbridgeinfo.css'
import wbridge1 from './images/wbridge1.jpg'
import wbridge2 from './images/wbridge2.jpg'
import wbridge4 from './images/wbridge4.jpg'

import { motion } from 'framer-motion';

const WeighbridgeInfo = () => {


  const variants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <>
      <section className="hero-main">
        <div className="hero-text-box">
          <h1>Experience a seamless transition into the future of weighbridge operations with the Growbean Unmanned RFID Weighbridge System</h1>
        </div>
      </section>

      <section className="section_globe">
        <div className="row">
          <h2 className="globe_head">Transform Your Weighbridge Operations</h2>
        </div>
        <div className="row">
          <div className="col_1_of_2">
            <div className="left_para">
              <h3 className="sub_head">
                Implement Advanced Technology
              </h3>

              <p className="para">
                Automated Weighing Systems:

                RFID Integration: Use Radio Frequency Identification (RFID) technology to automatically identify and record vehicle weights without manual intervention.
                ANPR (Automatic Number Plate Recognition): Automatically identify and log vehicle details using camera systems.
                Software Solutions:

                Weighbridge Management Software: Implement robust software to handle data logging, reporting, and integration with other systems like ERP (Enterprise Resource Planning) and inventory management.
                Cloud-Based Systems: Use cloud-based solutions for real-time data access and remote monitoring.
              </p>

              <h3 className="sub_head">
                Improve Physical Infrastructure
              </h3>

              <p className="para">
                Digital Weighbridges: Upgrade to digital weighbridges for better accuracy, reliability, and ease of maintenance compared to analog systems.

                Traffic Management:

                Clear Signage: Ensure clear signage to guide vehicles efficiently through the weighing process.
                Designated Waiting Areas: Create designated waiting areas to prevent congestion around the weighbridge.
              </p>

              <h3 className="sub_head">
                Enhance Data Handling and Reporting
              </h3>

              <p className="para">
                Automated Reporting: Implement systems that automatically generate and send reports to relevant stakeholders, reducing the need for manual data entry and minimizing errors.

                Data Integration: Ensure seamless integration with existing business systems to streamline operations and reduce duplication of effort.
              </p>

              

            </div>
          </div>

          <div className="col_1_of_2">
            <div className="composition" >
              <img src={wbridge1} alt="img1" className="composition_pohoto  composition_pohoto_1" />
              <img src={wbridge2} alt="img2" className="composition_pohoto  composition_pohoto_2" />
              <img src={wbridge4} alt="img3" className="composition_pohoto  composition_pohoto_3" />
            </div>
          </div>
        </div>


        {/* <div className='sub_container'>
              <h3 className="sub_head">
                Improve User Experience
              </h3>

              <p className="para">
                Training and Support:

                Regular Training Sessions: Conduct regular training for operators and staff to ensure they are proficient with the latest technologies and procedures.
                Technical Support: Provide easy access to technical support for quick resolution of issues.
                User-Friendly Interfaces: Ensure the software and systems have intuitive interfaces that are easy to use for all operators.
              </p>

              <h3 className="sub_head">
                Optimize Operational Efficiency
              </h3>

              <p className="para">
                Standard Operating Procedures (SOPs): Develop and implement clear SOPs to ensure consistent and efficient weighbridge operations.

                Maintenance Schedule: Establish a regular maintenance schedule to ensure the weighbridge and associated equipment are always in optimal working condition.

                Redundant Systems: Implement backup systems to ensure continuous operation in case of equipment failure.
              </p>

              <h3 className="sub_head">
                Utilize Real-Time Data and Analytics
              </h3>

              <p className="para">
                Real-Time Monitoring: Use real-time monitoring systems to keep track of weighbridge operations and quickly identify and address any issues.

                Data Analytics: Leverage data analytics to identify patterns, optimize operations, and make informed decisions based on historical data.
              </p>

              <h3 className="sub_head">
                Security and Compliance
              </h3>

              <p className="para">
                Compliance with Regulations: Ensure that weighbridge operations comply with all relevant local and international regulations and standards.

                Security Measures: Implement security measures such as surveillance cameras, secure access controls, and regular audits to prevent fraud and ensure data integrity.
              </p>
              </div> */}


<div className='sub_container'>
      <motion.h3
        className="sub_head"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.5 }}
        variants={variants}
      >
        Improve User Experience
      </motion.h3>

      <motion.p
        className="para"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        variants={variants}
      >
        Training and Support:
        <br />
        Regular Training Sessions: Conduct regular training for operators and staff to ensure they are proficient with the latest technologies and procedures.
        <br />
        Technical Support: Provide easy access to technical support for quick resolution of issues.
        <br />
        User-Friendly Interfaces: Ensure the software and systems have intuitive interfaces that are easy to use for all operators.
      </motion.p>

      <motion.h3
        className="sub_head"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.5 }}
        variants={variants}
      >
        Optimize Operational Efficiency
      </motion.h3>

      <motion.p
        className="para"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        variants={variants}
      >
        Standard Operating Procedures (SOPs): Develop and implement clear SOPs to ensure consistent and efficient weighbridge operations.
        <br />
        Maintenance Schedule: Establish a regular maintenance schedule to ensure the weighbridge and associated equipment are always in optimal working condition.
        <br />
        Redundant Systems: Implement backup systems to ensure continuous operation in case of equipment failure.
      </motion.p>

      <motion.h3
        className="sub_head"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.5 }}
        variants={variants}
      >
        Utilize Real-Time Data and Analytics
      </motion.h3>

      <motion.p
        className="para"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        variants={variants}
      >
        Real-Time Monitoring: Use real-time monitoring systems to keep track of weighbridge operations and quickly identify and address any issues.
        <br />
        Data Analytics: Leverage data analytics to identify patterns, optimize operations, and make informed decisions based on historical data.
      </motion.p>

      <motion.h3
        className="sub_head"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.5 }}
        variants={variants}
      >
        Security and Compliance
      </motion.h3>

      <motion.p
        className="para"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        variants={variants}
      >
        Compliance with Regulations: Ensure that weighbridge operations comply with all relevant local and international regulations and standards.
        <br />
        Security Measures: Implement security measures such as surveillance cameras, secure access controls, and regular audits to prevent fraud and ensure data integrity.
      </motion.p>
    </div>


              
      </section>
    </>
  )
}

export default WeighbridgeInfo