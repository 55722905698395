import React from "react";
import "./ContactOnHome.css";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
const ContactOnHome = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 300,
    });
  }, []);

  return (
    <>
      {/* <h2 className="text-center d-flex justify-content-center align-items-center mt-5">
        <div className="bg-success span-line"></div>
        <div className="m-2 he-1">Contact Us</div>
        <div className="bg-success span-line"></div>
      </h2>
      <p className="text-center">
        {" "}
        Explore the range of Contact Us we offer to boost your business:
      </p> */}
      <div className="contact-contentOnHome mb-5">
        <div className="leftcontentOnHome" data-aos="fade-right">
          <h2>Customer Support</h2>
          <p className="Text-in-Trusted">
            At Growbean, our customer support team is dedicated to providing
            exceptional service. We offer 24/7 assistance through phone, email,
            and live chat to resolve issues swiftly. Our knowledgeable experts
            guide you in maximizing our IT solutions, ensuring smooth operations
            and complete satisfaction. Feel free to reach out to us now with all
            your queries!
          </p>
          <div className="contact-buttonOnHome">
            <Link to="/contact">
              <button className="contact-buttonOnHome1">Contact Us</button>
            </Link>
          </div>
        </div>
        <div className="rightcontentOnHome" data-aos="fade-left">
          <h2>Customer Support</h2>
          <img
            className="contact-img-home"
            src="https://images.pexels.com/photos/7658399/pexels-photo-7658399.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=" supportImage"
          ></img>
        </div>
      </div>
    </>
  );
};

export default ContactOnHome;
