import React from 'react';
import './AboutBackground.css'

const AboutBackground = () => {
    return (
        <div className="about-background">
            <div className="background-image"></div>
            <div className="overlay"></div>
            <div className="content-about">
                <h1 className='bold-h1'>About Us</h1>
                <div className="quote-container">
                    <p className="quote">"Growbean Technologies Pvt. Ltd. is mainly into products and services in various domains like Bizpetro, Sugar industries, eCommerce, Customer Sales and Support, Communication, Social media, Enterprise Resource Planning (ERP), Business Process Automation (BPA)."</p>
                    <p className="quote">" This aim led us to form an IT company known as Growbean Technologies."</p>
                </div>
            </div>
        </div>
    );
};

export default AboutBackground;

