import React, { useState } from 'react';
import "./WeighbridgeAutomation.css"
import { motion } from 'framer-motion';
import pdf from "../../assets/weighbridge-automation-Growbean.pdf"
import weighbridge from "../../assets/weighbridge.png"


const WeighbridgeAutomation = () => {
  const [showContent, setShowContent] = useState(false);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdf;
    link.download = 'Weighbridge_Automation.pdf';
    link.click();

  };

  const handleReadMore = () => {
    setShowContent(true);
  };

  


  return (
    <div>
      <div className='download-button-div'>
        <h1 className='wba-heading'>Weighbridge Automation</h1>
        
      </div>
      <hr style={{
        width : '40vw',
        margin : 'auto'
      }}/>  


      
       
<div className='wba-video-container'>
        <div className='video-containe'>
        <motion.div
        className='videos'
          initial={{ x: -200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {/* <video src={video1} controls /> */}
          <h4>English</h4>
          <iframe src="https://drive.google.com/file/d/1pZzdKcq6PHh2oIfA_ugi7J7d809UEPWq/preview" class="video_frames" width="550"    height="350px"  allow="autoplay"></iframe>


        </motion.div>
        <motion.div
        className='videos'
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {/* <video src={video2} controls /> */}
          <h4>Hindi</h4>
          <iframe src="https://drive.google.com/file/d/1MU8GcJan7lQ-FSSr10RIYHjfMngyoZWy/preview" class="video_frames" width="550"    height="350px"  allow="autoplay"></iframe>
        </motion.div>
        </div>
        <div className='sub-container'>
        <motion.div
        className='videos'
          initial={{ y: 200, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {/* <video src={video3} controls /> */}
          <h4>Marathi</h4>
          <iframe src="https://drive.google.com/file/d/1SFYDP_fGvCPiWEOLJRnlv6c0tFl6abRL/preview" class="video_frames" width="550"   height="350px"  allow="autoplay"></iframe>
        </motion.div>
      </div>
      </div>
      
      

<div className='wba-content'>
        <motion.p
          className='wba-para'
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          Weighbridge automation refers to the integration of advanced technology and systems to automate the process of weighing vehicles, particularly heavy trucks, as they transport goods. The primary aim of weighbridge automation is to improve accuracy, efficiency, and security in the weighing process. Here is a detailed look at the various aspects of weighbridge automation:
        </motion.p>

        <motion.h3
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          How Does It Works?
        </motion.h3>

        <div className='image' id="image"
         initial={{ x: -200, opacity: 0 }}
         whileInView={{ x: 0, opacity: 1 }}
         viewport={{ once: true }}
         transition={{ duration: 1 }}>
          <img src={weighbridge} alt='weighning mechanism'
           initial={{ x: -200, opacity: 0 }}
           whileInView={{ x: 0, opacity: 1 }}
           viewport={{ once: true }}
           transition={{ duration: 1 }}/>
        </div>

        <motion.div
          className='wba-content-div'
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <h4>1. Authorization :</h4>
          <p className='wba-paras'>
            This is a fail-safe method to ensure that only Authorized Vehicle are
entering the premises. Only after the data is captured from the RFID
system.  Checks unauthorized entry of vehicles by using
buzzer And boom barrier.
Same vehicle not weighment up to last weighment
process complete.
Facility to take daily ones empty vehicle weight and
use all days (for internal weighment).

          </p>
          
        </motion.div>

        <motion.div
          className='wba-content-div'
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <h4>2. Positioning :</h4>
          <p className='wba-paras'>
            Non Positioning vehicle do not give accurate Weighment, this
leads to wrong data to capture and affect the weighment
process. Our Unmanned System utilized position
sensors (Weighbridge Centric System) to ensure that every
single Vehicle weighment is accurate.
          </p>
          
        </motion.div>

        {showContent && (
          <>
            <motion.div
              className='wba-content-div'
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1 }}
            >
              <h4>3. Surveillance :</h4>
              <p className='wba-paras'>
                CCTV/IP cameras are present to enhance security and
safety. The system automatically captures various images of
the Vehicle ,and stores it along with the weighment data. Real time camera view available at security
cabin for all the weigh bridge. System can generate different kind of MIS reports.

              </p>
            </motion.div>

            <motion.div
              className='wba-content-div'
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1 }}
            >
              <h4>4. Integration :</h4>
              <p className='wba-paras'>
                After the gross/tare weights are measured, all the information,
including the photos of the Vehicle,
are easily integrated with any type of ERP/SAP Platforms. Application support to multi DataBase (SQL
SERVER/MS Access/Oracle/IBM DB2)
Vehicle Positioning System through Sensors.
              </p>
              
            </motion.div>

            <motion.div
              className='wba-content-div'
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1 }}
            >
              <h4>5. Cost-Cutting :</h4>
              <p className='wba-paras'>
              The Cards are reusable hence the stationary cost is
eliminated
Completely man less
Reduces operation cost significantly.
Helps to control malpractic. Significantly Reduce Transaction time.

              </p>
              
            </motion.div>

            <motion.div
              className='wba-content-div'
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1 }}
            >
              <h4>6. Alert :</h4>
              <p className='wba-paras'>
              Alerts unauthorized entry of vehicles by using buzzer And
boom barrie.
Avoid Proxy Weighment of Vehicle. Traffic Lights to regulate the Process.


              </p>
              
            </motion.div>
          </>
        )}

        {!showContent && (
          <motion.p
            className="read-more"
            onClick={handleReadMore}
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          >
            Read More...
          </motion.p>
        )}
      </div>


    

     

  <div className='download-button-div'>
 <motion.div
          initial={{ x: 0 }}
          animate={{ x: [0, -10, 10, -10, 10, 0] }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          <button onClick={handleDownload} className="download-button">Download PDF</button>
        </motion.div>
 </div>
    </div>
  );
}

export default WeighbridgeAutomation;
