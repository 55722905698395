import React, { useRef } from "react";
import { IoIosClose } from "react-icons/io";

import "./Model.css";
function Model({ name, logo, actualContent, onClose, classes }) {
  const modelRef = useRef();
  const closeModel = (e) => {
    if (modelRef.current === e.target) {
      onClose();
    }
  };

  return (
    <>
      <div ref={modelRef} onClick={closeModel} className="model-main">
        <div className="model-main-div">
          <div className="model-header">
            <div className="model-header-heading">
              <h2 className="model-header-h1">{name}</h2>
            </div>
            <button onClick={onClose} className="model-header-button">
              <IoIosClose />
            </button>
          </div>
          <div className={`model-mid-logo ${classes}`}>
            <img src={logo} alt="" />
          </div>
          <div className="model-actualContent">{actualContent}</div>
          <div className="model-closeButton">
            <button onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Model;
