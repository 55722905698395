import CardForHowWeWork from "../../component/CardForHowWeWork/CardForHowWeWork";
import "./HowWeWork.css";
import img2 from "./Images/ui-ux.svg";
import Aos from "aos";
import img11 from "./Images/img1.jpg";
import "aos/dist/aos.css";
import { useEffect } from "react";

const HowWeWork = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 300,
    });
  }, []);
  return (
    <>
      <div className="hwwHeader">
        <div className="left-hwwHeader" data-aos="fade-right">
          <h1 className="left-hwwHeader-h1">HOW WE WORK</h1>
          <h2 className="left-hwwHeader-h2">We create value in every aspect</h2>
          <p className="left-hwwHeader-p">
            In our collaborative journey, achieving results with a quality match
            is not just a goal but the cornerstone of progress. We commence our
            partnership by actively listening to your concerns, challenges,
            requirements, and objectives, because your story is the foundation
            of our strategy. This initial phase unfolds into a genuine effort to
            grasp the intricacies of your business, market sector, and
            competitors, blending this acquired information seamlessly with our
            technical know-how, expertise, and extensive research to craft the
            most effective solution for you. At the heart of our approach are
            three guiding principles - Honesty, Trust, and Passion, ensuring a
            human touch to our professional collaboration. We don't just work on
            projects; we invest ourselves in understanding your narrative,
            offering not just solutions but a partnership fueled by integrity,
            reliability, and enthusiasm.
          </p>
        </div>
        <div className="right-hwwHeader" data-aos="zoom-in-up">
          <img src={img11} alt="" className="right-hwwHeader-img" />
        </div>
      </div>
      <div className="mid-hww">
        <div className="mid-left">
          <h2 className="mid-hww-h2">How we are different!</h2>
          <p className="mid-hww-p">
            We go beyond merely working with a diverse technologies( including
            Python, PHP, Laravel, Oracle, MongoDB, Unity 3D, Firebase, Azure,
            AWS, and Power BI,) as we also boast the agility to swiftly adapt to
            and embrace the latest ones. Each of our services is meticulously
            customized with thoughtful design, emphasizing visual excellence,
            seamless navigation, and significant market impact. The efficiency
            of our approach is underlined by the commitment and dedication of
            our team members and managers, who collaborate seamlessly to bring
            each project to successful fruition.
          </p>
        </div>
        <div className="mid-right" data-aos="zoom-out">
          <img src={img2} alt="differentImage" />
        </div>
      </div>
      <div className="hww-card-section">
        <div className="hww-card-section-h1">
          <h1>The Ideal Processes We Follow</h1>
        </div>
        <CardForHowWeWork />
      </div>
    </>
  );
};

export default HowWeWork;
