// import "./ServiceCards.css";
import "./ServiceCards.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
const ServiceCards = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 300,
    });
  }, []);
  return (
    <>
      <div id="servise-card" className="row main">
        <h2 className="text-center d-flex justify-content-center align-items-center">
          <div className="bg-success span-line"></div>
          <div className="m-2 he-1">Our Services</div>
          <div className="bg-success span-line"></div>
        </h2>
        <p className="text-center">
          {" "}
          Explore the range of services we offer to boost your business:
        </p>

        {/* <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
          <div className="overflow-hidden position-relative cards">
            <div className="card-img-container">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0ASE0QcEZfdO7y7SjTcrm0Md_BXSZM4JPxw&usqp=CAU"
                class="card-img"
                alt="..."
              />
            </div>
            <div class="card-img-overlay">
              <h5 class="card-title">E-Commerce</h5>
              <p class="card-text">This content is a little bit longer.</p>
              <div className="know-more">
                <Link to="/ecommers" className="btn btn-success btn-services">
                  Know more
                  <FontAwesomeIcon
                    className="arrow-icon"
                    icon={faAnglesRight}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div> */}

        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
          <div className="overflow-hidden position-relative cards">
            <div className="card-img-container">
              <img
                src="https://www.laprogressive.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTg2ODY3MDIxOTYwMzI0NjAw/e-commerce-450.jpg"
                class="card-img"
                alt="..."
              />
            </div>
            <div class="card-img-overlay">
              <h5 class="card-title">E-Commerce</h5>
              <p class="card-text">
                Revolutionizing commerce through seamless e-commerce.
              </p>
              <div class="know-more">
                <Link to="/ecommerce" className="btn btn-success btn-services">
                  Know more
                  <FontAwesomeIcon
                    className="arrow-icon"
                    icon={faAnglesRight}
                    fade
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
          <div className="overflow-hidden position-relative cards">
            <div className="card-img-container">
              <img
                src="https://images.pexels.com/photos/4145037/pexels-photo-4145037.jpeg?auto=compress&cs=tinysrgb&w=600"
                class="card-img"
                alt="..."
              />
            </div>
            <div class="card-img-overlay">
              <h5 class="card-title">System Integrator</h5>
              <p class="card-text">
              Streamlining Operations Through System Integration Solutions.
              </p>
              <div class="know-more">
                <Link
                  to="/systemintegrator"
                  className="btn btn-success btn-services"
                >
                  Know more
                  <FontAwesomeIcon
                    className="arrow-icon"
                    icon={faAnglesRight}
                    fade
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
          <div className="overflow-hidden position-relative cards">
            <img
              src="https://www.shutterstock.com/image-photo/social-media-concept-man-click-260nw-121297852.jpg"
              class="card-img"
              alt="..."
            />
            <div class="card-img-overlay">
              <h5 class="card-title">Security & Surveillance <br/> System</h5>
              <p class="card-text">
              Advanced Security and Surveillance Solutions.
              </p>
              <div class="know-more">
                <Link to="/securityserveillance" className="btn btn-success btn-services">
                  Know more
                  <FontAwesomeIcon
                    className="arrow-icon"
                    icon={faAnglesRight}
                    fade
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
          <div className="overflow-hidden position-relative cards">
            <img
              src="https://images.pexels.com/photos/3183165/pexels-photo-3183165.jpeg?auto=compress&cs=tinysrgb&w=600"
              class="card-img"
              alt="..."
            />
            <div class="card-img-overlay">
              <h5 class="card-title">
                Enterprise <br />
                Resource Planning
              </h5>
              <p class="card-text">
                Streamlining operations with integrated ERP software
              </p>
              <div class="know-more">
                <Link to="/erp" className="btn btn-success btn-services">
                  Know more
                  <FontAwesomeIcon
                    className="arrow-icon"
                    icon={faAnglesRight}
                    fade
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
          <div className="overflow-hidden position-relative cards">
            <img
              src="https://images.pexels.com/photos/8867630/pexels-photo-8867630.jpeg?auto=compress&cs=tinysrgb&w=600"
              class="card-img"
              alt="..."
            />
            <div class="card-img-overlay">
              <h5 class="card-title">IT Infrastructure</h5>
              <p class="card-text">
              Building a Robust IT Infrastructure for Seamless Operations
              </p>
              <div class="know-more">
                <Link
                  to="/itinfrastructure"
                  className="btn btn-success btn-services"
                >
                  Know more
                  <FontAwesomeIcon
                    className="arrow-icon"
                    icon={faAnglesRight}
                    fade
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
          <div className="overflow-hidden position-relative cards">
            <img
              src="https://www.laprogressive.com/.image/c_limit%2Ccs_srgb%2Cq_auto:good%2Cw_700/MjA2Mzg2Mjg4OTYwNDgwNTk2/financial-planning-1200.webp"
              class="card-img "
              alt="..."
            />
            <div class="card-img-overlay">
              <h5 class="card-title">Customized  Software  <br/>Development</h5>
              <p class="card-text">Bespoke Software Solutions Tailored to Your Needs.</p>
              <div class="know-more">
                <Link to="/softwaredevelopment" className="btn btn-success btn-services">
                  Know more
                  <FontAwesomeIcon
                    className="arrow-icon"
                    icon={faAnglesRight}
                    fade
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCards;
