import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Navbar2.css";
import Logo from "./logo.png";
import { Link } from "react-router-dom";

function Navbar2() {
  const [expanded, setExpanded] = useState(false);

  const toggleNav = () => {
    setExpanded(!expanded);
  };

  return (
    <Navbar
      id="nav"
      expand="lg"
      expanded={expanded}
      className="bg-body-tertiary p-0 nav2-container"
    >
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img src={Logo} className="Logo" alt="Logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle onClick={toggleNav} aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto margin-start">
            <p className="m-2">
              <Link className="fs-5 text-black" onClick={toggleNav} to="/">
                Home
              </Link>
            </p>
            <p className="m-2">
              <Link
                className="fs-5 text-black"
                onClick={toggleNav}
                to="/services"
              >
                Services
              </Link>
            </p>
            <NavDropdown
              title="Products"
              id="basic-nav-dropdown"
              className="nav-drop-down"
            >
              <NavDropdown.Item
                onClick={toggleNav}
                href="https://bizpetro.com"
                target="blank"
              >
                Bizpetro
              </NavDropdown.Item>
              <NavDropdown.Item onClick={toggleNav} href="/weighbridgeAutomation">
                Weighbridge Automation
              </NavDropdown.Item>
              <NavDropdown.Item onClick={toggleNav} href="/crushermanagement">

                Crusher Management System{" "}

              </NavDropdown.Item>
            </NavDropdown>

            {/* <NavDropdown
              title="About Us"
              id="basic-nav-dropdown"
              className="nav-drop-down"
            >
              <NavDropdown.Item onClick={toggleNav} href="/about">
                Company
              </NavDropdown.Item>
              <NavDropdown.Item onClick={toggleNav} to="/team">
                Team
              </NavDropdown.Item>
              <NavDropdown.Item onClick={toggleNav} href="/work">
                How we work
              </NavDropdown.Item>
              <NavDropdown.Item onClick={toggleNav} href="/contact">
                Contact
              </NavDropdown.Item>
            </NavDropdown> */}

            {/* After change into link tag */}

            {/* <NavDropdown
              title="About Us"
              id="basic-nav-dropdown"
              className="nav-drop-down"
            >
              <NavDropdown.Item onClick={toggleNav}>
                <Link to="/about" style={{ color: "#000" }}>
                  Company
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={toggleNav}>
                <Link to="/team" style={{ color: "#000" }}>
                  Team
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={toggleNav}>
                <Link to="/work" style={{ color: "#000" }}>
                  How we work
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={toggleNav}>
                <Link to="/contact" style={{ color: "#000" }}>
                  Contact
                </Link>
              </NavDropdown.Item>
            </NavDropdown> */}
            <NavDropdown
              title="About Us"
              id="basic-nav-dropdown"
              className="nav-drop-down"
            >
              <a
                className="d-block ps-3 text-black py-1 droupdown-link"
                onClick={toggleNav}
                href="/about"
              >
                Company
              </a>
              <a
                className="d-block ps-3 text-black py-1 droupdown-link"
                onClick={toggleNav}
                href="/team"
              >
                Team
              </a>

              <a
                className="d-block ps-3 text-black py-1 droupdown-link"
                onClick={toggleNav}
                href="/work"
              >
                How we work
              </a>
              <a
                className="d-block ps-3 text-black py-1 droupdown-link"
                onClick={toggleNav}
                href="/contact"
              >
                Contact
              </a>
            </NavDropdown>

            <p className="m-2">
              <Link
                onClick={toggleNav}
                className="fs-5 text-black"
                to="/career"
              >
                Career
              </Link>
            </p>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbar2;
