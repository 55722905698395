import React from "react";
import "./MoreServices.css";
import { motion } from "framer-motion";
import softwareDev from './images/softwareDevBanner.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneVolume,
  faPhone,
  faEnvelope,
  faBackward,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
function SoftwareDevelopment() {
  const leftVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  // const leftVariants = {
  //   hidden: { opacity: 0, x: 100 },
  //   visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  // };

  return (
    <>
      <h2 className="ecommerce-heading">Customized Software Development</h2>
      <div className="service-container" id="top">
        <div className="service-data-container">
          <div className="image-container">
            <p className="hover-text">At Growbean we're passionate about helping businesses thrive in the digital age. That's why we offer a full spectrum of web and app development services designed to bring your vision to life and propel your brand forward.</p>

            <img
              className="img-moredetails"
              src={softwareDev}
            />
          </div>

          <div className="content-section">
            <motion.h1
              className="fw-bold mt-5"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              Growbean: Customized Software Development Solutions
            </motion.h1>
            {/* <motion.h5
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
             System Integration Services
            </motion.h5> */}
            <motion.p
              className="mt-3 text-body-secondary"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              Websites that Convert We create stunning, user-friendly websites that not only showcase your brand but also drive user engagement and conversions.
              Looking to sell online? We develop robust and secure e-commerce platforms tailored to your specific needs, making it easy for customers to browse, purchase, and manage their orders.
              Empower your team to easily update and manage your website content with user-friendly CMS solutions.
             
            </motion.p>

            <motion.h2
              className="fw-bold"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              Software Development Services
            </motion.h2>
            <motion.p
              className="text-body-secondary"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >

              <br />
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">1.Mobile App Development (iOS & Android):</span>{" "}
                Reach your audience where they are with captivating mobile apps designed for both iOS and Android devices.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">2.Enterprise App Development:</span>{" "}
                We develop feature-rich enterprise applications to automate tasks, improve communication, and boost productivity within your organization.
                Cross-Platform App Development: Expand your reach with cost-effective cross-platform app development, ensuring your app functions flawlessly across multiple platforms.

              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">3.Deep Industry Knowledge:</span>{" "}
                Our team possesses extensive experience across various industries, allowing us to tailor solutions that align with your specific market needs.
                Agile Development Methodology: We embrace agile development practices to deliver high-quality solutions quickly and efficiently, with continuous client feedback woven into the process.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">4.Focus on User Experience (UX): </span>{" "}
                We prioritize user experience in everything we do, crafting intuitive interfaces and seamless navigation to keep your users engaged.
                Scalable Solutions: We build solutions that can grow alongside your business, ensuring they remain effective as your needs evolve.
              </motion.p>

            </motion.p>
          </div>

        </div>
      </div>
      <div className="d-flex justify-content-around m-5">
        <Link to="/itinfrastructure">
          <button class="bn53">
            <FontAwesomeIcon icon={faBackward} /> Prev
          </button>
        </Link>
        <Link to="/ecommerce">
          <button class="bn53">
            Next <FontAwesomeIcon icon={faForward} />
          </button>
        </Link>
      </div>
    </>
  );
}

export default SoftwareDevelopment;
