import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  About,
  Contact,
  Service,
  OurTeam,
  HowWeWork,
  Ecommers,
  SystemIntegrator,
  ERP,
  ItInfrastructure,
  SoftwareDevelopment,
  SecurityServeillance,
  Career,
  Socialmedia,
  Weighbridge,
  CustomerSupport,
  Career2,
  ServiceCards,
  WeighbridgeInfo,
  Crusher_Management,
} from "./routes/routes";
import Navbar2 from "./component/Navbar2/Navbar2";
import Footer from "./component/Footer/Footer";
import ScrollToTop from "./ScrollToTop.js";
import WeighbridgeAutomation from "./component/WeighbridgeAutomation/WeighbridgeAutomation.js";
// import { Crusher_Management } from "./routes/routes";
function App() {
  return (
    <BrowserRouter>
      <Navbar2 />
      <ScrollToTop />
      <Routes>
        <Route path="/systemintegrator" element={<SystemIntegrator />} />
        <Route path="/erp" element={<ERP />} />
        <Route path="/itinfrastructure" element={<ItInfrastructure />} />
        <Route path="/softwaredevelopment" element={<SoftwareDevelopment />} />
        <Route path="/weighbridgeinfo" element={<WeighbridgeInfo />} />
        <Route
          path="/securityserveillance"
          element={<SecurityServeillance />}
        />
        <Route path="/ecommerce" element={<Ecommers />} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/service" element={<Service />} />
        <Route path="/team" element={<OurTeam />} />
        <Route path="/work" element={<HowWeWork />} />
        <Route path="/career" element={<Career />} />
        <Route path="/services" element={<ServiceCards />} />
        <Route
          path="/weighbridgeAutomation"
          element={<WeighbridgeAutomation />}
        />
        <Route path="/crushermanagement" element={<Crusher_Management />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
