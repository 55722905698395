import React from 'react';
import './AboutContent.css'

const AboutContent = () => {
    return (
        <div className="content-container">
            <div className="left-content">
                <h2 className="blue-heading">Why Choose Us</h2>
                <p className='left-para'>
With more than 20 years of established business experience in manufacturing products and services in different industries and verticals.
<br/>
<br/>

The promoters of Growbean observed that though there has been high internet penetration within the Indian market. But access to quality software solutions available to start-ups, small and medium-sized enterprises in India is even lower.
<br/>
<br/>

Our promoters visualized that there are opportunities for providing the IT products and services to small and medium start-ups business that helps their businesses run faster and easier. This aim led us to form an IT company known as Growbean Technologies.</p>
            </div>
            <div className="right-content">
                {/* <h3 className='cofounder-heading'>Co-Founder</h3> */}
                {/* <p className='color-head'>Growbean</p> */}
                <img  className="img-cofounder"src="https://images.pexels.com/photos/6913193/pexels-photo-6913193.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="Co-Founder" />
            </div>
        </div>
    );
};

export default AboutContent;
