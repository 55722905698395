import React, { useState } from "react";
// import jsPDF from 'jspdf';
import dummypdf from "../../../src/assets/Crusher1.pdf";
import image from "../../assets/Cruher1.png";
import "./Crusher_Management.css";
import { motion } from "framer-motion";
const Crusher_Management = () => {
  const [showContent, setShowContent] = useState(false);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = dummypdf;
    link.download = "Crusher Management.pdf";
    link.click();
  };

  const handleReadMore = () => {
    setShowContent(true);
  };

  return (
    <div>
      <div className="download-button-div">
        <h1 className="wba-heading"> Crusher Management System </h1>
      </div>
      <hr
        style={{
          width: "40vw",
          margin: "auto",
        }}
      />
      <div className="wba-video-container">
        <div className="video-containe">
          <motion.div
            className="videos"
            initial={{ x: -200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            {/* <video src={video1} controls /> */}
            <h4>English</h4>
            <iframe
              src="https://drive.google.com/file/d/1Ax-zhXGPFmPZQCnhAZ-kEI01yF23pgsP/preview"
              class="video_frames"
              width="550"
              height="350px"
              allow="autoplay"
            ></iframe>
          </motion.div>
          <motion.div
            className="videos"
            initial={{ x: 200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            {/* <video src={video2} controls /> */}
            <h4>Hindi</h4>
            <iframe
              src="https://drive.google.com/file/d/1TGmHzqIoOhIk_DJMpWmHkjopIN5wnpSa/preview"
              class="video_frames"
              width="550"
              height="350px"
              allow="autoplay"
            ></iframe>
          </motion.div>
        </div>
        <div className="sub-container">
          <motion.div
            className="videos"
            initial={{ y: 200, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            {/* <video src={video3} controls /> */}
            <h4>Marathi</h4>
            <iframe
              src="https://drive.google.com/file/d/1xVYI4OK_6cRrxauNoxe4iRrSgrmgOB8L/preview"
              class="video_frames"
              width="550"
              height="350px"
              allow="autoplay"
            ></iframe>
          </motion.div>
        </div>
      </div>

      <div className="wba-content">
        <motion.p
          className="wba-para"
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          Crusher management systems, also known as crusher production
          management systems (CPMS), are like the brains of a modern crushing
          plant. They go beyond simply automating tasks; they provide a
          comprehensive suite of tools to optimize and streamline every aspect
          of your operation.
        </motion.p>

        <div
          className="image"
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <img className="crusher_image"
            src={image}
            alt="crusher management"
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          />
        </div>
        {/* <motion.h3
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          How Does It Works?
        </motion.h3> */}

        <motion.div
          className="wba-content-div"
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <h4>1.Data Collection :</h4>
          <p className="wba-paras">
            <span className="wba-subheading">Sensors:</span>
            Installed throughout the crushing plant, sensors continuously
            collect real-time data on various aspects of the operation. This
            data can include: Vibration levels of crushers to identify potential
            equipment issues. Temperature readings to ensure crushers operate
            within optimal ranges. Weight of material on conveyor belts to track
            production rates and material flow. Particle size of crushed
            material to monitor quality control.
          </p>
          <p className="wba-paras">
            <span className="wba-subheading">
              PLC (Programmable Logic Controller): :
            </span>
            The PLC acts as the intermediary, receiving data from the sensors.
            Based on pre-programmed logic and setpoints, the PLC controls and
            automates equipment operation, such as adjusting feed rates or
            starting/stopping crushers.
          </p>
        </motion.div>

        <motion.div
          className="wba-content-div"
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <h4>2.Data Processing and Analysis:</h4>
          <p className="wba-paras">
            <span className="wba-subheading">CMS Software:</span>
            {/* <span className='wba-subheading'>CMS Software:</span> */}
            The software processes the collected data, translating it into
            actionable insights. It performs functions like: Real-time
            monitoring: Displays key metrics like production rates, equipment
            health, and material flow on a user interface. Data analysis:
            Identifies trends and patterns in the data to pinpoint
            inefficiencies or potential problems. Alerts and notifications:
            Triggers alerts for operators to intervene when equipment
            malfunctions are detected or quality control parameters are
            breached.
          </p>
        </motion.div>

        {showContent && (
          <>
            <motion.div
              className="wba-content-div"
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1 }}
            >
              <h4>3. User Interaction and Control:</h4>
              <p className="wba-paras">
                <span className="wba-subheading">User Interface </span>
                Operators can access the CMS software through a user-friendly
                interface. This allows them to: Monitor the crushing process in
                real-time through dashboards and reports. Adjust settings for
                equipment operation based on production requirements. Schedule
                preventive maintenance based on system recommendations or
                pre-defined intervals. Generate reports on various aspects like
                production performance, inventory levels, and equipment health.
              </p>
            </motion.div>

            <motion.div
              className="wba-content-div"
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 1 }}
            >
              <h4>4.Output and Decision Making:</h4>
              <p className="wba-paras">
                <span className="wba-subheading">Reports and Analytics </span>
                The CMS generates comprehensive reports that provide valuable
                insights into various aspects of the crushing operation. This
                data empowers informed decision-making for: Optimizing
                production flow and maximizing output. Implementing preventive
                maintenance strategies to extend equipment life and reduce
                downtime. Adjusting inventory levels to minimize carrying costs
                and prevent stockouts. Improving quality control processes to
                ensure consistent product quality.
              </p>
              <p className="wba-paras">
                <span className="wba-subheading">
                  Overall, a crusher management system creates a closed-loop
                  system:
                </span>
                Sensors collect data. The PLC and software process and analyze
                the data. Operators make informed decisions based on the
                insights. Actions are taken to optimize the crushing process
                based on those decisions. The cycle continues, leading to a
                continuously improving crushing operation.
              </p>
            </motion.div>
          </>
        )}

        {!showContent && (
          <motion.p
            className="read-more"
            onClick={handleReadMore}
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
          >
            Read More...
          </motion.p>
        )}
      </div>

      {/* <hr /> */}

      {/* <div className='wba-content'>
  <motion.h3
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          Features & Benefits
        </motion.h3>
  </div> */}

      <div className="download-button-div">
        <motion.div
          initial={{ x: 0 }}
          animate={{ x: [0, -10, 10, -10, 10, 0] }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          <button onClick={handleDownload} className="download-button">
            Download PDF
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default Crusher_Management;
