import React from "react";
import "./MoreServices.css";
import { motion } from "framer-motion";
import itInfraBanner from './images/itinfraBanner.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneVolume,
  faPhone,
  faEnvelope,
  faBackward,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
function ItInfrastructure() {
  const leftVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  // const leftVariants = {
  //   hidden: { opacity: 0, x: 100 },
  //   visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  // };

  return (
    <>
      <h2 className="ecommerce-heading">IT Infrastructure</h2>
      <div className="service-container" id="top">
        <div className="service-data-container">
          <div className="image-container">
            <p className="hover-text">IT services encompass a wide range of services related to information technology  Our tailored solutions enhance efficiency, optimize resource management, and improve data flow in bean cultivation. Through seamless integration, GrowBean ensures a cohesive approach across all aspects of farming operations, empowering farmers to make informed decisions, boost productivity, and achieve sustainable growth.</p>

            <img
              className="img-moredetails"
              src={itInfraBanner}
            />
          </div>

          <div className="content-section">
            <motion.h1
              className="fw-bold mt-5"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              Growbean: IT Infrastructure Solutions
            </motion.h1>
            {/* <motion.h5
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
             System Integration Services
            </motion.h5> */}
            <motion.p
              className="mt-3 text-body-secondary"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              At Growbean, we understand that a robust IT infrastructure is the backbone of seamless business operations. Our comprehensive IT infrastructure services are designed to provide your organization with a stable, scalable, and secure foundation that supports all your technological needs. Our tailored solutions enhance efficiency, optimize resource management, and improve data flow in bean cultivation. Through seamless integration, GrowBean ensures a cohesive approach across all aspects of farming operations, empowering farmers to make informed decisions, boost productivity, and achieve sustainable growth.
            </motion.p>

            <motion.h2
              className="fw-bold"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
             IT Infrastructure Services
            </motion.h2>
            <motion.p
              className="text-body-secondary"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >

              <br />
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">1. Network Services:</span>{" "}
                Setting up and maintaining computer networks, including hardware, software, and security protocols.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">2.Software Development:</span>{" "}
                Designing, developing, and maintaining software applications tailored to specific business needs.

              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">3.Hardware Support:</span>{" "}
                Providing maintenance and support for computer hardware, peripherals, and devices.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">4.Cloud Computing:</span>{" "}
                Offering services related to cloud storage, computing, and infrastructure to enable remote access and scalability.
              </motion.p>

              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">5.Cybersecurity:</span>{" "}
                Implementing measures to protect systems, networks, and data from cyber threats and attacks.
              </motion.p>

              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">5.Data Management:</span>{" "}
                Managing and organizing data, including storage, backup, retrieval, and analysis.
              </motion.p>
      
            </motion.p>
          </div>

        </div>
      </div>
      <div className="d-flex justify-content-around m-5">
        <Link to="/erp">
          <button class="bn53">
            <FontAwesomeIcon icon={faBackward} /> Prev
          </button>
        </Link>
        <Link to="/softwaredevelopment">
          <button class="bn53">
            Next <FontAwesomeIcon icon={faForward} />
          </button>
        </Link>
      </div>
    </>
  );
}

export default ItInfrastructure;
