import React from 'react';
import './Technologies.css';

const Technologies = () => {
  return (
    <div className="technologies-container">
      <h1 className='tech-head'>Technologies We Work</h1>
      <h2 className='tech-subhead'> 
 "Tech fuels innovation, reshaping how we interact, work, and solve problems in the digital era."</h2>
      <div className="row">
        <div className="tech-item">
          <img  className="tech-img" src="https://cdn.worldvectorlogo.com/logos/netframework-1.svg" alt=".NET" />
         
        </div>
        <div className="tech-item">
          <img src="https://static-00.iconduck.com/assets.00/react-icon-2048x2048-o8k3ymqa.png" alt="React" />
       
        </div>
        <div className="tech-item">
          <img src="https://upload.wikimedia.org/wikipedia/commons/c/c1/PHP_Logo.png" alt="PHP" />
          
        </div>
        <div className="tech-item">
          <img src="https://cdn3.iconfinder.com/data/icons/logos-and-brands-adobe/512/21_Angular-512.png" alt="Angular" />
         
        </div>
      </div>
      <div className=" row1 second-row">
        <div className="tech-item">
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRenukgrd9NcNuMCloYXsD5aspirPo4-o2OA&usqp=CAU" alt="java" />
          
        </div>
        <div className="tech-item">
          <img src="https://netcominfotech.in/wp-content/uploads/2023/05/Webo.OracleProfile-1024x1024-1.png" alt="Oracle" />
         
        </div>
        <div className="tech-item">
          <img src="https://www.project-disco.org/wp-content/uploads/2018/04/Android-logo.jpg" alt="Android" />
       
        </div>
        <div className="tech-item">
          <img src="https://kunalcybersecurity.com/wp-content/uploads/2023/08/Python-Symbol.png" alt="Python" />
        
        </div>
      </div>
    </div>
  );
};

export default Technologies;
