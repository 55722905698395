import React from "react";
import "./MoreServices.css";
import { motion } from "framer-motion";
import securityBanner from './images/securityBanner.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneVolume,
  faPhone,
  faEnvelope,
  faBackward,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
function SystemIngegrator() {
  const leftVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  // const leftVariants = {
  //   hidden: { opacity: 0, x: 100 },
  //   visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  // };

  return (
    <>
      <h2 className="ecommerce-heading">Security & Surveillance System</h2>
      <div className="service-container" id="top">
        <div className="service-data-container">
          <div className="image-container">
            <p className="hover-text">With features such as high-definition video capture, motion detection, and remote access, our services ensure 24/7 protection and peace of mind.  Trust Growbean to enhance your security infrastructure, deter potential threats, and keep your operations secure."</p>

            <img
              className="img-moredetails"
              src={securityBanner}
            />
          </div>

          <div className="content-section">
            <motion.h1
              className="fw-bold mt-5"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              Growbean: Security & Surveillance System Solutions
            </motion.h1>
            {/* <motion.h5
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
             System Integration Services
            </motion.h5> */}
            <motion.p
              className="mt-3 text-body-secondary"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              "Growbean's Security & Surveillance System services provide comprehensive solutions to safeguard your business premises and assets. We helped businesses with security system installation or IT security solutions, client testimonials might mention it on their website. Our advanced systems integrate state-of-the-art cameras, access controls, and monitoring tools to deliver real-time surveillance and robust security management. With features such as high-definition video capture, motion detection, and remote access, our services ensure 24/7 protection and peace of mind.  Trust Growbean to enhance your security infrastructure, deter potential threats, and keep your operations secure."
            </motion.p>

            <motion.h2
              className="fw-bold"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >
              Security & Surveillance System Services
            </motion.h2>
            <motion.p
              className="text-body-secondary"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={leftVariants}
            >

              <br />
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">1. Security Focus:</span>{" "}
                Regular security audits conducted by Growbean experts to identify vulnerabilities and potential risks.
                Detailed reports provided to clients, along with recommendations for enhancing security measures.
                Real-time monitoring to detect and prevent fraudulent transactions.
                AI and machine learning algorithms to identify suspicious activities.
                Automated alerts and actions to minimize potential losses and fraud risks.
                Integration with trusted and secure payment gateways.
                Protection of payment data through tokenization and encryption.
                Reduces the risk of payment fraud and enhances customer trust.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">2.Real-time Alerts:</span>{" "}
                Tailored alert settings configured by Growbean to match the specific needs and preferences of each client.
                Granular control over alert thresholds, frequency, and notification channels for optimal monitoring.
                24/7 monitoring of client systems and networks by Growbean's advanced monitoring tools and technologies.
                Instant detection of anomalies, suspicious activities, and potential security threats in real-time.
                Instant alerts delivered to clients via multiple communication channels, including email, SMS, and dashboard notifications.
                Rapid response protocols implemented by Growbean to address alerts promptly and mitigate risks before they escalate.

              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">3.Automatic Detection:</span>{" "}
                Behavioral analysis techniques employed by Growbean to establish baseline behavior for client systems and detect deviations indicative of security breaches.
                Automatic identification of anomalous behavior patterns, such as unusual network traffic, unauthorized access attempts, or abnormal user behavior.
                Automated vulnerability scanning conducted by Growbean's detection systems to identify potential weaknesses and security vulnerabilities in client environments.
                Regular scanning and assessment of system configurations, software versions, and patch levels to proactively address security risks.
              </motion.p>
              <motion.p
                className="text-body-secondary"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={leftVariants}
              >
                <span className="text-black fw-bold">4. Multiple Alert Channels:</span>{" "}
                Growbean offers customizable alert notification preferences tailored to each client's requirements and preferences.
                Clients can choose from a variety of communication channels, including email, SMS, phone calls, and mobile push notifications.
                Growbean ensures redundancy in alert delivery by utilizing multiple channels simultaneously.
                In case of one channel failure, alerts are automatically rerouted through alternative channels to ensure timely delivery.
                Integration of alerting systems with collaboration tools such as Slack, Microsoft Teams, and PagerDuty by Growbean.
                Seamless communication and coordination among team members to facilitate rapid response to critical alerts.
              </motion.p>
      
            </motion.p>
          </div>

        </div>
      </div>
      <div className="d-flex justify-content-around m-5">
        <Link to="/systemintegrator">
          <button class="bn53">
            <FontAwesomeIcon icon={faBackward} /> Prev
          </button>
        </Link>
        <Link to="/erp">
          <button class="bn53">
            Next <FontAwesomeIcon icon={faForward} />
          </button>
        </Link>
      </div>
    </>
  );
}

export default SystemIngegrator;
