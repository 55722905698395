/* eslint-disable jsx-a11y/anchor-is-valid */
import "./OurTeam.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faInstagram } from '@fortawesome/free-solid-svg-icons'
// import "./Amol.jpeg"
import Amol from "./Amol.jpeg";
import Amit from "./amit.png";
import jitendra from "./jitendra.jpg";
import sarang from "./sarang sir.jpg";
import sammer from "./sammer.jpeg";

import {
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const OurTeam = () => {
  return (
    <>
      <div className="video-background">
        <video autoPlay loop muted className="video">
          <source
            src="https://player.vimeo.com/external/374265101.sd.mp4?s=dac19b26168d8f08bcb743230003266a0e15e23d&profile_id=164&oauth2_token_id=57447761"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="container my-5 content-scrollable">
        <section className="team-section text-center dark-grey-text">
          <h3 className="font-weight-bold heading-title">
            Our LeaderShip Team
          </h3>
          <div className="hrline pb-2 mb-4"></div>
          <p className="text-muted w-responsive mx-auto mb-5 title">
            "Embarking on a Journey of Growth: Introducing the Remarkable
            Individuals Who Drive Innovation and Cultivate Success at Growbean,
            Where Every Seed Holds the Promise of a Bright Future!".
          </p>

          <div className="row text-center text-md-left">
            {/* Team member 1 */}
            <div className="col-lg-6 col-md-12 mb-5 d-md-flex justify-content-between">
              <div className="avatar mb-md-0 mb-4 mx-4 w-">
                <img
                  src={Amol}
                  className="rounded z-depth-1 team-images"
                  alt="Sample avatar"
                />
              </div>
              <div className="mx-4 team-info">
                <h4 className="font-weight-bold mb-3">Amol Gujar</h4>
                <h6 className="font-weight-bold grey-text mb-3">
                  DIRECTOR & CO-FOUNDER
                  <div className="hrline2 pb-2 mb-4"></div>
                </h6>

                <p className="grey-text">
                  {/* GrownBean, founded by visionary leaders, stands at the intersection of innovation and growth */}
                  {/* Amol Gujar, the visionary Chairman & Managing Director of Growbean Technologies, leads with unwavering commitment and expertise, driving innovation and sustainable growth in the realm of technology solutions */}
                  Amol Gujar, Growbean Technologies' visionary Chairman &
                  Managing Director, leads with commitment, expertise, driving
                  innovation, and sustainable growth in technology solutions
                </p>

                <ul className="team-icon">
                  <li>
                    <a href="#" className="twitter">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="LinkedIn">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="Instagram">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Team member 2 */}
            <div className="col-lg-6 col-md-12 mb-5 d-md-flex justify-content-between">
              <div className="avatar mb-md-0 mb-4 mx-4">
                <img
                  src={Amit}
                  className="rounded z-depth-1 team-images"
                  alt="Sample avatar"
                />
              </div>
              <div className="mx-4 team-info">
                <h4 className="font-weight-bold mb-3">Amit Wankar</h4>
                <h6 className="font-weight-bold grey-text mb-3">
                  DIRECTOR & CO-FOUNDER
                </h6>
                <p className="grey-text">
                  {/* Head of Marketing crafts strategies, enhancing brand visibility and market presence.
                   */}
                  Amit Wankar, Growbean's Marketing Director, innovates
                  strategies, boosts online presence, and engages customers with
                  creativity and data-driven precision.
                </p>

                <ul className="team-icon">
                  <li>
                    <a href="#" className="twitter">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="LinkedIn">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="Instagram">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mb-5 d-md-flex justify-content-between">
              <div className="avatar mb-md-0 mb-4 mx-4">
                <img
                  src={jitendra}
                  className="rounded z-depth-1 team-images"
                  alt="Sample avatar"
                />
              </div>
              <div className="mx-4 team-info">
                <h4 className="font-weight-bold mb-3">Jitendra Pimpale</h4>
                <h6 className="font-weight-bold grey-text mb-3">
                  DIRECTOR & CO-FOUNDER
                </h6>
                <p className="grey-text">
                  {/* Financial Strategies oversees financial strategies, planning, and fiscal operations. */}
                  Jitendra Pimpale, Growbean Technologies' Director of Finance,
                  navigates the company's fiscal landscape with strategic
                  acumen, fostering sustainable growth and financial resilience
                </p>

                <ul className="team-icon">
                  <li>
                    <a href="#" className="twitter">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="LinkedIn">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="Instagram">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mb-5 d-md-flex justify-content-between">
              <div className="avatar mb-md-0 mb-4 mx-4">
                <img
                  src={sarang}
                  className="rounded z-depth-1 team-images"
                  alt="Sample avatar"
                />
              </div>
              <div className="mx-4 team-info">
                <h4 className="font-weight-bold mb-3">Sarang Kuthe</h4>
                <h6 className="font-weight-bold grey-text mb-3">
                  DIRECTOR & CO-FOUNDER
                </h6>
                <p className="grey-text">
                  {/* Leadership drives efficiency Directs and manages operational functions for organizational efficiency. */}
                  Sarang Kuthe, Director of Operations at Growbean Technologies,
                  leads strategic growth and operational excellence, driving
                  innovation for impactful technology solutions.
                </p>

                <ul className="team-icon">
                  <li>
                    <a href="#" className="twitter">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="LinkedIn">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="Instagram">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mb-5 d-md-flex justify-content-between">
              <div className="avatar mb-md-0 mb-4 mx-4">
                <img
                  src={sammer}
                  className="rounded z-depth-1 team-images"
                  alt="Sample avatar"
                />
              </div>
              <div className="mx-4 team-info">
                <h4 className="font-weight-bold mb-3">Sameer Pathak </h4>
                <h6 className="font-weight-bold grey-text mb-3">
                  DIRECTOR & CO-FOUNDER
                </h6>
                <p className="grey-text">
                  {/* Leadership drives efficiency Directs and manages operational functions for organizational efficiency. */}
                  Sameer Pathak, Growbean Technologies' Director of Technical,
                  is a visionary leader driving innovation and overseeing the
                  development of cutting-edge solutions for the dynamic digital
                  landscape.
                </p>

                <ul className="team-icon">
                  <li>
                    <a href="#" className="twitter">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="LinkedIn">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="Instagram">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OurTeam;
