import logo from "./../Navbar/logo.png";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import "./Footer.css";
import { Link, useParams } from "react-router-dom";

export default function Footer() {
  return (
    <MDBFooter
      bgColor="light"
      className="text-center text-lg-start text-muted footr-comtainer"
    >
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <Link to="" className="me-4 text-reset">
            <MDBIcon color="secondary" fab icon="facebook-f" />
          </Link>
          <Link to="" className="me-4 text-reset">
            <MDBIcon color="secondary" fab icon="twitter" />
          </Link>
          <Link to="" className="me-4 text-reset">
            <MDBIcon color="secondary" fab icon="google" />
          </Link>
          <Link to="" className="me-4 text-reset">
            <MDBIcon color="secondary" fab icon="instagram" />
          </Link>
          <Link to="" className="me-4 text-reset">
            <MDBIcon color="secondary" fab icon="linkedin" />
          </Link>
          <Link to="" className="me-4 text-reset">
            <MDBIcon color="secondary" fab icon="github" />
          </Link>
        </div>
      </section>

      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <img
                  src={logo}
                  color="secondary"
                  height="90px"
                  icon="gem"
                  className="h-5"
                />
              </h6>
              <p>
                We are specialized in developing Easy to User Interface Software
                of web and mobile solutions for commercial India .
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Services</h6>
              <p>
                <Link
                  to="/ecommerce"
                  className="text-reset text-decoration-none"
                >
                  E-Commerce
                </Link>
              </p>
              <p>
                <Link
                  to="/systemintegrator"
                  className="text-reset text-decoration-none"
                >
                  System Integrator
                </Link>
              </p>
              <p>
                <Link
                  to="/securityserveillance"
                  className="text-reset text-decoration-none"
                >
                  Security & Surveillance System
                </Link>
              </p>
              <p>
                <Link
                  to="/erp"
                  className="text-reset text-decoration-none"
                >
                  Enterprise Resource Planning
                </Link>
              </p>
              <p>
                <Link to="/itinfrastructure" className="text-reset text-decoration-none">
                  IT Infrastructure
                </Link>
              </p>

              <p>
                <Link to="/softwaredevelopment" className="text-reset text-decoration-none">
                 Customized Software Development
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
              <p>
                <a href="/" className="text-reset text-decoration-none">
                  Home
                </a>
              </p>
              <p>
                <Link to="/about" className="text-reset text-decoration-none">
                  About Us
                </Link>
              </p>
              <p>
                <Link to="/contact" className="text-reset text-decoration-none">
                  Contact
                </Link>
              </p>
              <p>
                <Link to="/team" className="text-reset text-decoration-none">
                  Company
                </Link>
              </p>
              <p>
                <Link to="/contact" className="text-reset text-decoration-none">
                  Help
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p>
                <MDBIcon color="secondary" icon="home" className="me-2" />
                64/A, Ramana Maruti Nagar, Nandanvan, Nagpur, Maharashtra, India
                440024
              </p>
              <p>
                <MDBIcon color="secondary" icon="envelope" className="me-3" />
                amit@growbean.in
              </p>
              <p>
                <MDBIcon color="secondary" icon="phone" className="me-3" /> +91
                8149040335
              </p>
              {/* <p>
                <MDBIcon color='secondary' icon='print' className='me-3' /> + 01 234 567 89
              </p> */}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2024 Copyright:
        <Link className="text-reset fw-bold" to="https://growbean.in/">
          Growbean.com
        </Link>
        {/* <a className="text-reset fw-bold" href="https://growbean.in/">
          Growbean.in
        </a> */}
      </div>
    </MDBFooter>
  );
}
